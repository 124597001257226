<template>
  <v-app
    fluid
    style="-webkit-app-region: drag"
    v-if="UserProfile && UserProfile._id"
  >
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      :permanent="windowWidth > 620 ? true : false"
      color="white"
      app
    >
      <v-app-bar elevation="0" dark class="mt-2 white">
        <v-list-item>
          <img
            v-if="!mini"
            height="50px"
            src="@/assets/logo.jpg"
            max-height="110px"
            max-width="110px"
            alt="admin"
            class="mx-auto"
          />
        </v-list-item>
      </v-app-bar>

      <v-list shaped class="clickable">
        <template v-for="(item, i) in items">
          <div
            v-if="
              item.isAdmin === isAdmin ||
              (item.isUser === isUser && item.roles.includes(UserProfile.role))
            "
            :key="i"
            :disabled="item.disable"
          >
            <v-list-group
              v-if="item.children"
              v-model="item.model"
              :prepend-icon="item['icon-ctr']"
              :append-icon="item.model ? item.icon : item['icon-alt']"
              class="primary--text"
              active-class="textColor"
              de
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="list-item-title">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                route
                :to="child.route"
                class="primary--text"
                active-class="textColor"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="list-item-title">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              class="primary--text"
              active-class="textColor"
              route
              :to="item.route"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="list-item-title">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>

      <v-btn
        v-on:click="logoutMethods"
        color="irgib"
        class="clickable textColor2 white--text mb-0 ml-0"
        style="margin-top: 80%; width: 10%"
        dark
        width="auto"
      >
        <v-icon large>mdi-power</v-icon>
      </v-btn>
    </v-navigation-drawer>

    <v-app-bar app class="border appBar white">
      <v-app-bar-nav-icon
        size="30"
        @click.stop="drawer = !drawer"
        class="clickable irgib--text d-md-none"
      />
      <v-list-item-title class="text-capitalize irgib--text"
        ><span class="hidden-sm-and-down" width="200px"
          ><b style="color: #144881; font-size: 25px">IRGIB - </b>
          <b style="color: #144881; font-size: 25px">AFRICA</b></span
        ></v-list-item-title
      >

      <v-spacer />
      <v-app-bar color="rgba(0,0,0,0)" flat>
        <v-spacer></v-spacer>
        <div
          style="width: 240px"
          class="rounded irgib white--text text-center py-1 mr-5"
        >
          <span class="bold"> Solde : {{ UserProfile.balance }} FCFA </span>
        </div>

        <h5 style="width: 150px" class="irgib--text d-none d-md-block">
          {{ profile.firstName }} {{ profile.lastName }}
          <h6>{{ profile.role }}</h6>
        </h5>

        <div class="text-center">
          <v-menu
            v-model="profilMenu"
            :close-on-content-click="false"
            :nudge-width="500"
            offset-y
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                size="40"
                class="ml-2"
                color="grey lighten-4"
              >
                <v-img v-if="profile.avatar" :src="profile.avatar"></v-img>
                <v-icon v-else color="#144881"> mdi-account </v-icon>
              </v-avatar>
              <v-badge
                bordered
                bottom
                color="green"
                dot
                offset-x="-0"
                offset-y="15"
              ></v-badge>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img v-if="profile.avatar" :src="profile.avatar"></v-img>
                    <v-icon v-else color="#144881"> mdi-account </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ profile.firstName }}
                      {{ profile.lastName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      profile.currentPosition
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item>
                  <v-row
                    class="d-md-flex justify-space-around"
                    style="width: 100%"
                  >
                    <v-btn
                      v-on:click="updateUserProfile = true"
                      color="#fff"
                      class="clickable textColor2 white--tex mb-3 mb-md-0 mt-3 mt-md-0"
                      rounded
                      dark
                      width="auto"
                    >
                      <v-icon class="mr-5">mdi-account-alert-outline</v-icon>
                      Modifier le profil
                    </v-btn>

                    <v-btn
                      v-on:click="logoutMethods"
                      color="#fff"
                      class="clickable textColor4 white--tex mb-3 mb-md-0"
                      rounded
                      dark
                      width="auto"
                    >
                      Se déconnecter<v-icon class="ml-5">mdi-power</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <h6 class="ml-5">V - 0.2.7 (mvx)</h6>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-app-bar>
    </v-app-bar>

    <v-row class="mt-3">
      <v-slide-x-transition fluid duration="100">
        <router-view></router-view>
      </v-slide-x-transition>
    </v-row>

    <v-btn
      v-scroll="onScroll"
      bottom
      color="#fff"
      dark
      fab
      fixed
      right
      @click="toTop"
      class="clickable"
    >
      <v-icon color="#144881" size="50">mdi-chevron-up</v-icon>
    </v-btn>
    <!-- <v-card id="create">
      <v-speed-dial
        v-model="fab"
        bottom
        right
        :direction="left"
        transition="slide - y - reverse - transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn fab dark small color="indigo">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card> -->
    <v-row justify="center">
      <v-dialog v-model="updateUserProfile" max-width="500px">
        <v-card style="border-top: 10px solid #144881">
          <v-card-title>
            <span class="text-h5"
              >Modifier les information de l'utilisateur</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-img
                      rounded
                      elevation="1"
                      style="
                        border: 0.5px solid #144881;
                        padding: 20px;
                        border-radius: 20px;
                      "
                      class="mr-4"
                      v-if="profile.avatar"
                      :src="profile.avatar"
                      height="30rem"
                    ></v-img>
                    <v-icon
                      rounded
                      style="
                        border: 0.5px solid #144881;
                        padding: 20px;
                        border-radius: 20px;
                      "
                      v-else
                      elevation="1"
                      class="mr-4"
                      color="#144881"
                    >
                      mdi-account
                    </v-icon>
                    <v-file-input
                      small-chips
                      prepend-icon="mdi-camera"
                      truncate-length="50"
                      v-model="avatar"
                    ></v-file-input>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nom complet*"
                    v-model="profile.firstName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Prenom complet*"
                    v-model="profile.lastName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Mail*"
                    v-model="profile.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Téléphone*"
                    v-model="profile.phone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6"> </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Mots de passe Actuels*"
                    v-model="profile.oldPassword"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="nouveau mots de passe*"
                    v-model="profile.passwords"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Indique les informations requises</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog = false"> Fermer </v-btn>
            <v-btn color="success" @click="onUpdatedUserProfile">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
//import * as auth from '../services/auth_service'
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";

import { logout } from "@/api/auth";
import { updateUsers,checkUserAuth } from "@/api/user";

export default {
  props: {
    source: String,
  },
  data: () => ({
    windowWidth: 600,
    drawer: null,
    messages: 0,
    menuCall: false,
    menuRdv: false,
    mini: false,
    fab: false,
    menu: "",
    avatar: null,
    profilMenu: false,
    updateUserProfile: false,
    selected: "",

    items: [
      {
        icon: "mdi-home",
        text: "Accueil",
        route: "/",
        isAdmin: true,
        isUser: true,
        roles: [""],
      },
      {
        icon: "mdi-school-outline",
        text: "Année Scolaire",
        route: "/school-year",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-chart-bar",
        text: "Bilan",
        route: "/bilan",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-account-multiple",
        text: "Utilisateur",
        route: "/user",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-graph",
        text: "Domaine",
        route: "/faculty",
        isUser: true,
        roles: [ "Administrateur","Superviseur"],
      },
      {
        icon: "mdi-human-male-board",
        text: "Ue",
        route: "/ue",
        isUser: true,
        roles: ["Administrateur","Superviseur", "Manager BVU"],
      },
      {
        icon: "mdi-certificate",
        text: "Grade",
        route: "/grade",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-alarm-note",
        text: "Taux horaire",
        route: "/hourlyquota",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-account-tie",
        text: "Enseignant",
        route: "/teacher",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-domain",
        text: "Salle",
        route: "/hall",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-human-male-boy",
        text: "Parain",
        route: "/godfather",
        isUser: true,
        roles: [],
      },
      {
        icon: "mdi-cash",
        text: "Frais  additionel",
        route: "/additional-standard-fees",
        isUser: true,
        roles: ["Administrateur","Superviseur", "Resp.Fin."],
      },
      {
        icon: "mdi-percent",
        text: "Bourse d'étude",
        route: "/schorlarship",
        isUser: true,
        roles: ["Administrateur","Superviseur"],
      },
      {
        icon: "mdi-account-plus",
        text: "Inscription",
        route: "/student/register",
        isUser: true,
        roles: ["Administrateur","Superviseur", "Acceuil BVU"],
      },
      {
        icon: "mdi-cash-multiple",
        text: "Caisse",
        route: "/caisse",
        isUser: true,
        roles: ["Caisse", "Resp.Fin.", "Administrateur","Superviseur"],
      },
      {
        icon: "mdi-finance",
        text: "Finance",
        route: "/finance",
        isUser: true,
        roles: ["Resp.Fin.", "Administrateur","Superviseur"],
      },
      {
        icon: "mdi-account-group",
        text: "Etudiant",
        route: "/student",
        isUser: true,
        roles: ["Resp.Fin.", "Administrateur","Superviseur", "Manager BVU"],
      },
      {
        icon: "mdi-bell-badge",
        text: "Demandes",
        route: "/request",
        isUser: true,
        roles: ["Resp.Fin.", "Administrateur","Superviseur"],
      },
      {
        icon: "mdi-book-check",
        text: "Authentification document",
        route: "/document-validation",
        isUser: true,
        roles: [
          "Resp.Fin.",
          "Administrateur","Superviseur",
          "Manager BVU",
          "Acceuil BVU",
          "Caisse",
        ],
      },
      // {
      //   icon: "mdi-cog",
      //   text: "Paramètres",
      //   route: "/setting",
      //   isUser: true,
      //   roles:["Resp.Fin.","Administrateur","Superviseur","Manager BVU","Acceuil BVU","Caisse"]

      // },
      {
        icon: "mdi-plus",
        text: "Autre",
        route: "/other",
        isUser: true,
        roles: [
          "Resp.Fin.",
          "Administrateur","Superviseur",
          "Manager BVU",
          "Acceuil BVU",
          "Caisse",
        ],
      },
      //    {
      //   icon: "mdi-protocol",
      //   text: "Produit",
      //   route: "/product",
      //   isUser: true,
      // },
      {
        icon: "mdi-shield-account",
        text: "Management",
        route: "/management",
        isAdmin: true,
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "UserProfile",
      "AdminProfile",
      "priority",
      "callList",
      "rdvList",
    ]),
    isAdmin() {
      if (localStorage.getItem("admin-token")) return true;
      return false;
    },
    isUser() {
      if (localStorage.getItem("user-token")) return true;
      return false;
    },
    profile() {
      if (this.UserProfile && this.UserProfile._id) return this.UserProfile;
      else return this.AdminProfile;
    },
  },
  mounted() {
    if (this.isUser) {
      this.getUserProfile();

      this.checkUserAuthInterval = setInterval(async () => {
        await checkUserAuth({})
      }, 1000*60*5);
    }

    if (this.isAdmin) {
      this.getAdminProfile();
    }

    this.$nextTick(() => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeDestroy(){
    if(this.checkUserAuthInterval){
      clearInterval(this.checkUserAuthInterval)
    }
  },
  methods: {
    ...mapActions(["getAdminProfile", "getUserProfile"]),
    initialize() {
      if (this.isUser) {
        this.getUserProfile();
      }

      if (this.isAdmin) {
        this.getAdminProfile();
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    logoutMethods() {
      logout();
    },
    async onUpdatedUserProfile() {
      // update

      try {
        const formData = new FormData();
        if (this.avatar) {
          formData.append("avatar", this.avatar);
        }
        formData.append("lastName", this.profile.lastName);
        formData.append("firstName", this.profile.firstName);
        formData.append("email", this.profile.email);
        formData.append("phone", this.profile.phone);

        if (this.profile.passwords) {
          formData.append("oldPassword", this.profile.oldPassword);
          formData.append("password", this.profile.passwords);
        }

        await updateUsers({
          schema: formData,
        });

        defaultMethods.dispatchSuccess(
          this.$store,
          messages.updatedSuccessfully("L'utilisateur")
        );

        if (this.profile.passwords) {
          this.logoutMethods();
        }

        this.updateUserProfile = false;

        this.initialize();
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style lang="scss">
.list-item-title {
  font-size: 13px;
  font-weight: 700;
}

a {
  text-decoration: none;
}

.border-green {
  border: 1px rgba(0, 128, 0, 0.3) solid;
}
.border-orange {
  border: 1px rgba(255, 166, 0, 0.3) solid;
}
.border-grey {
  border: 1px rgb(202, 198, 198) solid;
}
.border-red {
  border: 1px rgba(255, 0, 0, 0.3) solid;
}

.appBar {
  // background: #b7b9bcf6 !important;
  // color: #fff !important;
  border-radius: 100px !important;
  border: 0.5 solid black;
  margin-bottom: 10px !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px !important;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
.textColor {
  color: #fff !important;
  font-weight: bolder;
}
.textColor2 {
  color: #144881 !important;
  font-weight: bolder;
}
.textColor4 {
  background-color: #144881 !important;
}
.border {
  border-right: 2px solid #144881 !important;
}
.textColor3 {
  background-color: #ffffff !important;
}
.clickable {
  -webkit-app-region: no-drag;
}
::-webkit-scrollbar {
  width: 10px;
  height: 15px;
  margin-left: 1500px !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #f6f6f9 !important;
}

.w-100 {
  width: 100%;
}

.border-t {
  border-top: 1px solid #ebebebaa;
}
</style>
